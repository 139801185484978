<template>
  <div class="historyOverview">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row">
        <div class="col-12 mb-4">
          <HistoryComments
            ref="Notelist"
            :history-comments="historyComments"
            @showNoteAdd="show"
          />
        </div>
        <div class="col-12 mb-4">
          <HistoryCountChart :history="historyComments" />
        </div>
        <div class="col-12">
          <BusiestInstallations />
        </div>
      </div>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <InstallationNote
          :installation-id="installationId"
          @close="close"
        />
      </Sidebar>
    </template>
  </div>
</template>

<script>
export default {
  name: "HistoryOverview",
  components: {
    HistoryCountChart: () => import('@/components/History/HistoryCountChart.vue'),
    BusiestInstallations: () => import('@/components/History/BusiestInstallations.vue'),
    HistoryComments: () => import('@/components/History/HistoryComments.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    InstallationNote: () => import('@/components/Installation/InstallationNote.vue')
  },
  data () {
    return {
      loading: true,
      historyComments: [],
      resizeListener: null,
      showSidebar: false,
      installationId: null
    }
  },
  computed: {
    commentsAvailable: function () {
      if (this.historyComments == null) {
        return;
      }
      if (this.historyComments.length == null) {
        return;
      }
      if (this.historyComments.length == 0) {
        return;
      }
      return true;
    },
    historyTypes: function () {
      if (!this.historyComments) {
        return [ "" ];
      }

      //Distinct (include every entry only once)
      let historyTypes = Array.from(new Set(this.historyComments.map(o=>o.type)));
      //Remove null values
      historyTypes = historyTypes.filter(function (e) {return e});
      return historyTypes.sort();
    }
  },
  created () {
    this.getHistoryEntries();
    this.resizeListener = window.addEventListener('resize', this.computeDisplay);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computeDisplay);
    this.resizeListener = null;
  },
  methods: {
    show (id) {
      this.installationId = id;
      this.showSidebar = true;
    },
    close () {
      this.installationId = null;
      this.showSidebar = false;
      this.getHistoryEntries();
    },
    getHistoryEntries () {
      this.loading = true;
      this.axios.get('/Installation/GetGroupedInstallationComments')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.historyComments = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>